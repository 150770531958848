// Home.jsx
import React from "react";
import style from "./Home.module.css";
import Navbar from "../../Components/Home/Navbar/Navbar";
import HeroSection from "../../Components/Home/HeroSection/HeroSection";

const Home = () => {
  return (
    <div className={style.homePage}>
      <Navbar />
      <HeroSection/>
    </div>
  );
};

export default Home;
