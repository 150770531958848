import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./WritingArea.module.css";
import DOMPurify from "dompurify"; // Import DOMPurify to sanitize HTML

const WritingArea = () => {
  const [value, setValue] = useState("");
  console.log("value:", value);

  // Sanitize the HTML content before rendering it
  const sanitizedHTML = DOMPurify.sanitize(value);

  // Define modules to include text alignment options
  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean'],                                        
      [{ 'align': [] }],
      [{ 'color': [] }, { 'background': [] }], // Additional font options
      [{ 'script': 'sub'}, { 'script': 'super' }], // Subscript and superscript
      [{ 'header': '3'}, {'header': '4'}, {'header': '5'}, {'header': '6' }], // More header options
      [{ 'font': ['Arial', 'Times New Roman', 'Verdana', 'Helvetica'] }], // More font options
    ],
  };
  

  return (
    <div className={styles.writingArea}>
      <h2>Write Your Story</h2>
      <ReactQuill 
        theme="snow" 
        value={value} 
        onChange={setValue} 
        modules={modules} // Pass modules with toolbar options
      />
      <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
    </div>
  );
};

export default WritingArea;
