import React from "react";
import style from "./Login.module.css";
import loginHeroImage from "../../Assets/LoginHeroImage.png";
import { IoClose } from "react-icons/io5";
import googleIcon from "../../Assets/google.svg";
import appleIcon from "../../Assets/apple.svg";
import { Link } from "react-router-dom";


const Login = () => {
  return (
    <div className={style.container}>
      <div className={style.leftContainer}>
        <div className={style.loginImageContainer}>
          <h2 className={style.loginHeroWord}>
            Unlock the power of
            <span> Writing </span>
            with your imagination
          </h2>
          <img src={loginHeroImage} alt="loginHeroImage" />
        </div>
      </div>
      <div className={style.rightContainer}>
        <div className={style.loginFormContainer}>
          <div className={style.loginLogo}>
            <p>I</p>
            <p>💛</p>
            <p>Story</p>
          </div>
          <div className={style.socialLoginSection}>
            <button className={style.googleLoginIconContainer}>
              <img
                src={googleIcon}
                alt="googleIcon"
                class={style.googleIconLogo}
              />
              <span>Continue With Google</span>
            </button>
            <button className={style.googleLoginIconContainer}>
              <img
                src={appleIcon}
                alt="appleIcon"
                class={style.googleIconLogo}
              />
              <span>Continue With Apple</span>
            </button>
            <button></button>
          </div>
          <div class={style.orLine}>
            <span>Or sign in with email</span>
          </div>
          <div className={style.formSection}>
            <input
              className={style.loginEmail}
              placeholder="🔒 Enter Your Email"
            />
            <input
              className={style.loginEmail}
              placeholder="🔑 Enter Your Password"
            />
            <div className={style.forgetPasswordButton}>Forgot Password?</div>
          </div>

          <button className={style.signInButton}>Sign In</button>
          <div className={style.dontHaveAccount}>
            Don't have an account?<span> Sign Up for Free</span>
          </div>
        </div>
        <div className={style.closeLogin}>
          <Link to="/" className={style.closeButton}>
            <IoClose />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
