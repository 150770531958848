import React, { useState } from "react";
import style from "./Navbar.module.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={style.navbar}>
      <div className={style.container}>
        <Link to="/" className={style.logoContainer}>
          <p>I</p>
          <p>💛</p>
          <p>Story</p>
        </Link>
        <div className={style.hamburger} onClick={toggleMenu}>
          &#9776; {/* Hamburger icon */}
        </div>
        <div className={`${style.navbarOptions} ${isOpen ? style.active : ""}`}>
          <Link to="/writing_area">Product</Link>
          <p>Pricing</p>
          <p>Blog</p>
          <p>About Us</p>
          <p>Contact Us</p>
          {/* "Get Started" Button */}
          <Link to="/login">
            <button className={style.button_1} role="button">
              <span className={style.button_1_shadow}></span>
              <span className={style.button_1_edge}></span>
              <span className={`${style.button_1_front} ${style.text}`}>
                Get Started
              </span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
