import React, {  } from "react";
import style from "./HeroSection.module.css";
import heroImage from "../../../Assets/test.jpg"

const HeroSection = () => {
  // var words = [
  //   "Intuitive Story Editor",
  //   "Personalized User Profiles",
  //   "Dynamic Story Discovery",
  //   "Interactive Community Engagement",
  //   "Privacy and Sharing Controls",
  // ];

  return (
    <div className={style.heroSection}>
      <div className={style.container}>
        <div className={style.heroicWord}>
          <p className={style.tagLine}>Ultimate Story Writing Companion.</p>
          <h1>Unleash Your Imagination, Pen Your Tale.</h1>
          {/* <div className={style.wordFlick}></div> */}
          <button className={style.getStartedButton}>
            Start Writing - It's Free
          </button>
          <img className={style.heroImage} src={heroImage} alt="heroImage"/>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;