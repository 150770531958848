import React from "react";
import WritingArea from "../../Components/WritingArea/WritingArea";
import Navbar from "../../Components/Home/Navbar/Navbar";

const Products = () => {
  return (
    <div>
      <Navbar />
      <WritingArea />
    </div>
  );
};

export default Products;
