import React from "react";
import "./App.css";
import AllRoutes from "./Routes/AllRoutes";

function App() {
  console.log("Rendering App component");
  return (
    <div className="App">
      <AllRoutes />
    </div>
  );
}

export default App;
